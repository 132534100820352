import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClientModule } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
      'Access-Control-Allow-Origin': '*'
    }
  )
};

@Injectable()
export class ExamsService {

  constructor(private http: HttpClient) { }

  getExamCategoryList(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exams-category', httpOptions)
      .pipe(
        tap(res => console.log('fetched exams category')),
        catchError(this.handleError('getExamCategoryList', []))
      );
  }

  getExamCourseList(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exams-course', httpOptions)
      .pipe(
        tap(res => console.log('fetched exams course')),
        catchError(this.handleError('getExamCourseList', []))
      );
  }

  getExamCourseSectionList(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exams-course-section', httpOptions)
      .pipe(
        tap(res => console.log('fetched exams-course-section')),
        catchError(this.handleError('getExamCourseSectionList', []))
      );
  }

  getUserByUsername(name: string) {
    const url = `${environment.apiUrl + '/exams-category-name-check'}/${name}`;
    return this.http.get<any[]>(url);
  }

  addExamCategory(exam): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/exams-category', exam, httpOptions).pipe(
      tap((exam: any) => console.log(`added user w/ id=${exam.id}`)),
      catchError(this.handleError<any>('addUser'))
    );
  }

  addExamCourse(exam): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/exams-course', exam, httpOptions).pipe(
      tap((exam: any) => console.log(`added exams-course w/ id=${exam.id}`)),
      catchError(this.handleError<any>('addExamCourse'))
    );
  }

  addExamCourseSection(exam): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/exams-course-section', exam, httpOptions).pipe(
      tap((exam: any) => console.log(`exams-course-section w/ id=${exam.id}`)),
      catchError(this.handleError<any>('addExamCourseSection'))
    );
  }

  getExamCategoryForMapping(id): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exam-mapping-getCategory/' + id, httpOptions)
      .pipe(
        tap(res => console.log('fetched exams category')),
        catchError(this.handleError('getExamCategoryForMapping', []))
      );
  }

  getExamCourseForMapping(id): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exam-mapping-getCourse/' + id, httpOptions)
      .pipe(
        tap(res => console.log('fetched exams course')),
        catchError(this.handleError('getExamCourseForMapping', []))
      );
  }

  getExamCourseSectionForMapping(id): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exam-mapping-getCourseSection/' + id, httpOptions)
      .pipe(
        tap(res => console.log('fetched exams course section')),
        catchError(this.handleError('getExamCourseSectionForMapping', []))
      );
  }

  addExamMapping(exam): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/exam-mapping', exam, httpOptions).pipe(
      tap((exam: any) => console.log(`addExamMapping w/ id=${exam.id}`)),
      catchError(this.handleError<any>('addExamMapping'))
    );
  }

  getUserExamCategoryForMapping(id): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/user-exam-mapping-getCategory/' + id, httpOptions)
      .pipe(
        tap(res => console.log('fetched user exams category')),
        catchError(this.handleError('getUserExamCategoryForMapping', []))
      );
  }

  getUserExamCourseSectionForMapping(userID, id): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/user-exam-mapping-getCourseSection/' + userID + '/' + id, httpOptions)
      .pipe(
        tap(res => console.log('fetched user exams course section')),
        catchError(this.handleError('getUserExamCourseSectionForMapping', []))
      );
  }

  getUserExamCourseForMapping(userID, categoryID, courseSectionID): Observable<any[]> {

    return this.http.get<any[]>(environment.apiUrl + '/user-exam-mapping-getCourse/' + userID + '/' + categoryID + '/' + courseSectionID, httpOptions)
      .pipe(
        tap(res => console.log('fetched user exams course')),
        catchError(this.handleError('getUserExamCourseForMapping', []))
      );
  }

  addUserExamMapping(exam): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/user-exam-mapping', exam, httpOptions).pipe(
      tap((exam: any) => console.log(`addUserExamMapping w/ id=${exam.id}`)),
      catchError(this.handleError<any>('addUserExamMapping'))
    );
  }

  /* Questio */
  getExamCategoryForUserIDWise(userID): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/question-getCategoryForUserIDWise/' + userID, httpOptions)
      .pipe(
        tap(res => console.log('getExamCategoryForUserIDWise')),
        catchError(this.handleError('getExamCategoryForUserIDWise', []))
      );
  }

  getExamCourseSectionForUserIDWise(userID, categoryID): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/question-getCourseSectionForUserIDWise/' + userID + '/' + categoryID, httpOptions)
      .pipe(
        tap(res => console.log('getExamCourseSectionForUserIDWise')),
        catchError(this.handleError('getExamCourseSectionForUserIDWise', []))
      );
  }

  getExamCourseForUserIDWise(userID, categoryID, courseSectionID): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/question-getCourseForUserIDWise/' + userID + '/' + categoryID + '/' + courseSectionID, httpOptions)
      .pipe(
        tap(res => console.log('getExamCourseForUserIDWise')),
        catchError(this.handleError('getExamCourseForUserIDWise', []))
      );
  }

  getQuestionList(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/question-list', httpOptions)
      .pipe(
        tap(res => console.log('getQuestionList')),
        catchError(this.handleError('getQuestionList', []))
      );
  }

  getQuestionListWithFilters(data): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/question-list-with-filters/' + data.category + '/' + data.courseSection + '/' + data.course, httpOptions)
      .pipe(
        tap(res => console.log('getQuestionListWithFilters')),
        catchError(this.handleError('getQuestionListWithFilters', []))
      );
  }

  getQuestionListWithFiltersWithQuestionTestNumber(userID, examId_, data): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/question-list-with-filters-with-question-test-number/' + userID + '/' + examId_ + '/' + data.category + '/' + data.courseSection + '/' + data.course + '/' + data.testNumber + '/' + data.isUnique, httpOptions)
      .pipe(
        tap(res => console.log('getQuestionListWithFilters')),
        catchError(this.handleError('getQuestionListWithFilters', []))
      );
  }


  addQuestion(question): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/question-list', question, httpOptions).pipe(
      tap((exam: any) => console.log(`addQuestion w/ id=${exam.id}`)),
      catchError(this.handleError<any>('addQuestion'))
    );
  }

  questionAction(id, flag): Observable<any> {
    const url = `${environment.apiUrl + '/question-action'}/${id}`;
    return this.http.put(url, flag, httpOptions).pipe(
      tap(_ => console.log(`questionAction=${id}`)),
      catchError(this.handleError<any>('questionAction'))
    );
  }

  getExamSubItem(data): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/exam-sub-list/' + data.category + "/" + data.course, httpOptions)
      .pipe(
        tap(res => console.log('getExamSubItem')),
        catchError(this.handleError('getExamSubItem', []))
      );
  }

  addExamSubItem(subItem): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/add-exam-sub-item', subItem, httpOptions).pipe(
      tap((exam: any) => console.log(`examSubItem w/ json=${subItem}`)),
      catchError(this.handleError<any>('examSubItem'))
    );
  }

  updateExamSubItem(subItem, id): Observable<any> {
    const url = `${environment.apiUrl + '/add-exam-sub-item'}/${id}`;
    return this.http.put(url, subItem, httpOptions).pipe(
      tap(_ => console.log(`updateExamSubItem=${id}`)),
      catchError(this.handleError<any>('updateExamSubItem'))
    );
  }

  getExamSubItemForEdit(id): Observable<any> {
    return this.http.get<any[]>(environment.apiUrl + '/get-exam-sub-item-for-edit/' + id, httpOptions)
      .pipe(
        tap(res => console.log('getExamSubItemForEdit')),
        catchError(this.handleError('getExamSubItemForEdit', []))
      );
  }


  deleteExamSubItem(id): Observable<any> {
    const url = `${environment.apiUrl + '/delete-exam-sub-item'}/${id}`;
    return this.http.delete(url, httpOptions).pipe(
      tap(_ => console.log(`deleteExamSubItem=${id}`)),
      catchError(this.handleError<any>('deleteExamSubItem'))
    );
  }

  /* */
  getPreExamList(userID): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/pre-exam-list/' + userID, httpOptions)
      .pipe(
        tap(res => console.log('getPreExamList')),
        catchError(this.handleError('getPreExamList', []))
      );
  }


  getPreExamDateList(userID, examID): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/pre-exam-date-list/' + userID + '/' + examID, httpOptions)
      .pipe(
        tap(res => console.log('getPreExamDateList')),
        catchError(this.handleError('getPreExamDateList', []))
      );
  }

  getPreExamCourseWithQuestionCount(userID, examID, sectionID): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/pre-exam-course-list_with_question_count/' + userID + '/' + examID + '/' + sectionID, httpOptions)
      .pipe(
        tap(res => console.log('getPreExamCourseWithQuestionCount')),
        catchError(this.handleError('getPreExamCourseWithQuestionCount', []))
      );
  }

  addPreExamQuestionPaper(questionIDs, id): Observable<any> {
    const url = `${environment.apiUrl + '/add-pre-exam-question-paper'}/${id}`;
    return this.http.put(url, questionIDs, httpOptions).pipe(
      tap(_ => console.log(`questionAction=${id}`)),
      catchError(this.handleError<any>('questionAction'))
    );
  }

  deletePreExamQuestionPaper(questionIDs, id): Observable<any> {
    const url = `${environment.apiUrl + '/delete-pre-exam-question-paper'}/${id}`;
    return this.http.put(url, questionIDs, httpOptions).pipe(
      tap(_ => console.log(`deletePreExamQuestionPaper=${id}`)),
      catchError(this.handleError<any>('deletePreExamQuestionPaper'))
    );
  }

  getAllReadyAssignedQuestionList(questionIds): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/pre-exam-assigned-question-list/' + questionIds, httpOptions)
      .pipe(
        tap(res => console.log('getAllReadyAssignedQuestionList')),
        catchError(this.handleError('getAllReadyAssignedQuestionList', []))
      );
  }

  /* for New PHP API */

  getDailyNews(data): Observable<any[]> {
    
    //return this.http.get(environment.apiUrl_v2_common+ '/getExamList?id='+this.appConfigService.examAppID)
    return this.http.get<any[]>(environment.apiUrl_for_php + '/getDailyNews?id='+ data.category)
      .pipe(
        tap(res => console.log('getDailyNews')),
        catchError(this.handleError('getDailyNews', []))
      );
  }

  
  addNewsAndUpdate(form): Observable<any> {
    return this.http.post<any>(environment.apiUrl_for_php + '/addDailyNews', form).pipe(
      tap((exam: any) => console.log(`addNewsAndUpdate w/ json=${form}`)),
      catchError(this.handleError<any>('addNewsAndUpdate'))
    );
  }

  editNewsAndUpdate(form): Observable<any> {
    return this.http.post<any>(environment.apiUrl_for_php + '/editDailyNews', form).pipe(
      tap((exam: any) => console.log(`editNewsAndUpdate w/ json=${form}`)),
      catchError(this.handleError<any>('editNewsAndUpdate'))
    );
  }

  
  deleteNewsAndUpdate(newsID, categoryID): Observable<any> {
    let param: any = {
      'categoryID': categoryID,
      'newsID': newsID
    };
    return this.http.delete<any[]>(environment.apiUrl_for_php + '/deleteDailyNews',  {params: param})
    .pipe(
      tap(res => console.log('deleteNewsAndUpdate')),
      catchError(this.handleError('deleteNewsAndUpdate', []))
    );

  }

  getNewsAndUpdateForEdit(id, categoryID): Observable<any> {
    let param: any = {
      'catgoryID': categoryID,
      'newsID': id
    };
    //return this.http.get<any[]>(environment.apiUrl + '/pre-exam-course-list_with_question_count/' + userID + '/' + examID + '/' + sectionID, httpOptions)
    return this.http.get<any[]>(environment.apiUrl_for_php + '/getDailyNewsForEdit',  {params: param})
      .pipe(
        tap(res => console.log('getNewsAndUpdateForEdit')),
        catchError(this.handleError('getNewsAndUpdateForEdit', []))
      );
  }

  addNewPreExamTable(categoryID): Observable<any> {
    return this.http.get<any>(environment.apiUrl_for_php + '/addNewPreExamTable?id='+ categoryID)
      .pipe(
        tap(res => console.log('addNewPreExamTable')),
        catchError(this.handleError('addNewPreExamTable', []))
      );
  }

  getPreExamDateListWithDetails(examID): Observable<any> {
    return this.http.get<any>(environment.apiUrl_for_php + '/getPreExamDateListWithDetails?id='+ examID)
      .pipe(
        tap(res => console.log('getPreExamDateListWithDetails')),
        catchError(this.handleError('getPreExamDateListWithDetails', []))
      );
  }

  deletePreExamDate(exanDateID, categoryID): Observable<any> {
    let param: any = {
      'categoryID': categoryID,
      'exanDateID': exanDateID
    };
    return this.http.delete<any[]>(environment.apiUrl_for_php + '/deletePreExamDate',  {params: param})
    .pipe(
      tap(res => console.log('deletePreExamDate')),
      catchError(this.handleError('deletePreExamDate', []))
    );

  }

  addNewPreExamDate(form): Observable<any> {
    return this.http.post<any>(environment.apiUrl_for_php + '/addNewPreExamDate', form).pipe(
      tap((exam: any) => console.log(exam)),
      catchError(this.handleError<any>('addNewPreExamDate'))
    );
  }

  
  getNewPreExamDateDetails(categoryID, preExamID): Observable<any> {
    let param: any = {
      'categoryID': categoryID,
      'preExamID': preExamID
    };
    //return this.http.get<any[]>(environment.apiUrl + '/pre-exam-course-list_with_question_count/' + userID + '/' + examID + '/' + sectionID, httpOptions)
    return this.http.get<any[]>(environment.apiUrl_for_php + '/getNewPreExamDateForEdit',  {params: param})
      .pipe(
        tap(res => console.log('getNewPreExamDateDetails')),
        catchError(this.handleError('getNewPreExamDateDetails', []))
      );
  }

  editNewPreExamDate(form): Observable<any> {
    return this.http.post<any>(environment.apiUrl_for_php + '/editNewPreExamDate', form).pipe(
      tap((exam: any) => console.log(`editNewPreExamDate w/ json=${form}`)),
      catchError(this.handleError<any>('editNewPreExamDate'))
    );
  }


  /* end here */

  /* Question End here */

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
