// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // for heroku
     apiUrl: ' https://sleepy-garden-72683.herokuapp.com',
  // for php
     apiUrl_for_php: 'http://exitosoft.in/cdac_exam_2020/public/index.php/webapi',

    
  //apiUrl: 'http://localhost:3000',
  //apiUrl_for_php: 'http://localhost:81/slimapp/public/index.php/webapi',


  //apiUrl: 'http://localhost:3000',
  //apiUrl: 'http://13.233.104.178:3001',
  //apiUrl: 'http://cdacapi.exitosoft.in/apiv1',

  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
