import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginModel } from './login.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { HttpClientModule } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class LoginService {

  // new BehaviorSubject<boolean>(true) :  set false (only for testing true)
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}

  private accessType_ = new BehaviorSubject<boolean>(false); 

  //for menu
  private accessType : any = "N";

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  get getAccessType_() {
    return this.accessType_.asObservable(); // {2}
  }


  getAccessType() {
    return this.accessType; // {2}
  }

  constructor(private router: Router, private http: HttpClient) {

  }

  //login(username: string, password: string): Observable<boolean> {
  login(user: LoginModel): Observable<boolean> {
    return this.http.post<{token: string}>(environment.apiUrl +'/login', {username: user.userName, password: user.password})
      .pipe(
        map(result => {

          console.log(result["obj"].token);
          //localStorage.setItem('access_token', result.token);
          localStorage.setItem('access_token', result["obj"].token);
          localStorage.setItem('userID', result["obj"].userID);
          this.loggedIn.next(true);

          this.accessType_.next(result["obj"].accessType);

          this.accessType = result["obj"].accessType;
         
          return true;
          //
          ///this.router.navigate(['home']);
        })
      );
  }

  /*
  login(user: LoginModel){
    if (user.userName !== '' && user.password !== '' ) { // {3}
      this.loggedIn.next(true);
      this.router.navigate(['home']);
    }
  }
  */

  logout() {                            // {4}
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }
}
