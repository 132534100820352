import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'questionOptionsPipe'
})
export class QuestionOptionsPipePipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: any, args?: any): any {

    //console.log(args);
    var newStr = "";
    var arr = value.split("#~#");
    var correctAnsArr = args.split(',');
    //console.log(arr);
    var newArr = [];
    if (arr.length > 0) {
      let j = 0;
      for (let i = 0; arr.length > i; i++) {
        if (arr[i].length > 0) {
          newArr.push(arr[i]);
          if (correctAnsArr.length > 0 && correctAnsArr[j] == i + 1) {
            newStr += '<div style="padding:4px; color:#29af2e;border-bottom:1px solid #eaeaea;    font-weight:500;">' + arr[i] + '</div><br/>';
            j++
          }
          else {
            newStr += '<div style="padding:4px; border-bottom:1px solid #eaeaea;">' + arr[i] + '</div><br/>';
          }
        }
      }
    }


    //newStr = this.replaceAt(newStr, newStr.lastIndexOf("<br/>"), "");
    //alert(newStr);

    //var tmp = '<span style="color:#b30000;">prince paliwal</span>';
    //return this._sanitizer.bypassSecurityTrustHtml('<div class="option-list">'+newArr.join('<br/>').toString()+'</div>');
    //return this._sanitizer.bypassSecurityTrustHtml(tmp);
    return this._sanitizer.bypassSecurityTrustHtml('<div class="option-list" style="margin-top:4px;">' + newStr.toString() + '</div>');
  }


}
